import React, { useState } from "react";
// import logo from "../../assets/images/myCareHorizontal.svg";
import logo from "../../assets/images/TeleSecure360logo.svg";
import { BiLockAlt } from "react-icons/bi";
import { NavLink, useLocation, Navigate, Link } from "react-router-dom";

const Header = ({ hide }) => {
  const location = useLocation();

  const path = location.pathname;

  const string = `hello`;
  const activeClass = "bg-primary mx-3 px-4 py-2 rounded-lg text-white";
  const defaultClass = "bg-white mx-3 px-4 py-2 rounded-lg text-primary border";
  
  {/* new design css  */ }
  const activeClassNew = "bg-primary px-4 py-2 rounded-t-lg text-white";
  const defaultClassNew = "bg-gray px-4 py-2 text-[#6A6A6A]";
  return (
    <>
      <div className="min-w-screen flex justify-between p-5 sticky top-0 bg-white z-10">
        <div className="flex space-x-5 align-center justify-center ">
          <img className="h-auto w-28 md:w-40" src={logo} alt="logo" />
        </div>
        <div>
          {!hide ? (
            <Link
              to="/"
              className="flex align-center justify-center space-x-3 bg-secondary px-3 py-2 rounded-lg font-bold text-white text-base md:px-4 md:py-3"
            >
              <span>Login</span>
              <BiLockAlt className="text-lg" />
            </Link>
          ) : (
            <Link
              to="/signup/personal"
              className="flex align-center justify-center space-x-3 bg-secondary px-3 py-2 rounded-lg font-bold text-white text-base md:px-4 md:py-3"
            >
              <span>Sign Up</span>
              <BiLockAlt className="text-lg" />
            </Link>
          )}
        </div>
      </div>
      {/* {!hide && (
        <div className="text-center text-text space-y-3 md:text-left md:px-5">
          <h5 className="tracking-widest md:text-sm">SIGN UP</h5>
          <h3 className="text-lg font-black ">Create Your Account</h3>
          <div className="flex flex-col md:flex-row md:justify-between">
            <h5 className="font-bold tracking-wide text-sm">
              PLEASE FILL OUT THE FORM TO CREATE YOUR USER ACCOUNT.
            </h5>
            <div className="my-3 flex flex-wrap justify-center items-center md:my-0 gap-1">
              <span
                className={
                  path == "/signup/personal" ? activeClass : defaultClass
                }
              >
                1. Personal Information
              </span>
              <span
                className={
                  path == "/signup/medical" ? activeClass : defaultClass
                }
              >
                2. Medical
              </span>
              <span
                className={path == "/signup/esign" ? activeClass : defaultClass}
              >
                3. E- Sign
              </span>
            </div>
          </div>
        </div>
      )} */}

      {/* new design  */}
      {!hide && (
        <div className="m-3 bg-gray rounded-t-lg text-center text-text md:text-left md:px-5">
          <h3 className="text-lg font-black py-5 ">Create Your Account</h3>
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-wrap justify-center items-center gap-x-8 gap-y-1">
              <span className={path == "/signup/personal" ? activeClassNew : defaultClassNew}>Personal Information</span>
              <span className={path == "/signup/medical" ? activeClassNew : defaultClassNew}>Medical</span>
              <span className={path == "/signup/esign" ? activeClassNew : defaultClassNew}>E- Sign</span>
            </div>
          </div>
          <hr />
        </div>
      )}
    </>
  );
};

export default Header;
