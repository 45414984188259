/* eslint-disable */
import React, { useState, useContext, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Country, State, City } from 'country-state-city';
import Navbar from "../../../components/Navbar/Navbar";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Countries, States } from "../../../services/Constants";
import Spin from "../../../components/Loader/Spin";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { BaseAPI, BaseURL } from "../../../services/services";
import Spinner from "../../../components/Loader/Spinner";
import Table from "../../../components/Table/TableWithoutPaginationForMedicine";

function ViewOrder() {
    //localStorage
    const user = localStorage.getItem("userData");
    const token = localStorage.getItem("token");
    const UserInfo = JSON.parse(user);

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    const textSize = { fontSize: '14px' };
    const textSizeWithColor = { fontSize: '14px', color: '#A1A1A1' };

    const [orderInfo, setOrderInfo] = useState([]);
    const [medicineList, setMedicineList] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    const myHeaders = new Headers({
        accept: "application/json",
        authorization: `${token}`,
        "Content-Type": "application/json",
    });

    const goBack = () => {
        navigate(-1);
    };

    const getOrderDetails = () => {
        setisLoading(true);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        BaseAPI(
            `${BaseURL}/doctor/getMedicineOrders?orderId=${state?.id}`,
            requestOptions
        )
            .then((result) => {
                if (result.status !== 200) {
                    toast.error(result.message);
                    setTimeout(() => {
                        navigate("/");
                    }, 3000);
                } else {
                    setOrderInfo(result.data?.orders[0]);
                    setMedicineList(result.data?.orders[0]?.medicinesAtBilling);
                }
                setisLoading(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong!");
            })
            .finally(() => setisLoading(false));
    };

    useEffect(() => {
        getOrderDetails();
    }, [state?.id])

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => (<div className='w-28' title={row.original?.name}>{row.original?.name.length > 30 ? row.original?.name?.slice(0, 30) + '...' : row.original?.name}</div>),
        },
        {
            Header: 'Medicine QTY.',
            accessor: 'Medicine_QTY',
            Cell: ({ row }) => (<div>{row.original?.quantity ? Number(row.original?.quantity) : Number(row.original?.dose) * Number(row.original?.duration)}</div>),
        },
        {
            Header: 'Duration/Dosage',
            accessor: 'duration',
            Cell: ({ row }) => (<div>{row.original?.duration}</div>),
        },
    ], [medicineList]);


    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();

        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDate.getDate())
        ) {
            return age - 1;
        }
        return age;
    };

    const getPdf = (id) => {
        let myHeaders = new Headers({
            accept: "application/json",
            authorization: `${token}`,
        });

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        return fetch(
            `${BaseURL}/doctor/getPdf?id=${id}`,
            requestOptions
        )
    }

    const handleViewInvoice = (id, data) => {
        getPdf(data?.invoiceId)
            .then((res) =>
                res.arrayBuffer()
            )
            .then(res => {
                const file = new Blob([res], { type: 'application/pdf' })
                const fileURL = URL.createObjectURL(file)
                window.open(fileURL)
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="Home bg-Light-gray min-h-screen w-full">

            <Navbar heading="Home / Order" />

            <div className="flex text-text py-5 items-center ">
                <div
                    className="bg-white rounded-lg cursor-pointer p-2 mx-3 shadow-back text-lg font-bold"
                    onClick={goBack}
                >
                    <HiOutlineChevronLeft />
                </div>
                <h1 className="text-lg font-bold">Order Details</h1>
            </div>

            <div className="bg-white rounded-lg mx-4 text-text shadow">
                {isLoading ? <Spinner /> : <>
                    <div className='p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                        <div className='font-semibold' style={textSize}>Order ID: {orderInfo?.uniqueId}</div>
                        <div className='font-semibold' style={textSize}>Date: {`${new Date(orderInfo?.createdAt)?.getFullYear()}/${String(new Date(orderInfo?.createdAt)?.getMonth() + 1).padStart(2, '0')}/${String(new Date(orderInfo?.createdAt)?.getDate()).padStart(2, '0')}`}</div>
                    </div>

                    <hr className='border' style={{ borderColor: '#A1A1A1' }} />

                    <div className='p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-5'>
                        <div>
                            <div className='font-semibold underline underline-offset-8 pb-4' style={textSize}>Medicine Prescribed By:</div>
                            <div className='font-bold'>{orderInfo?.doctorData && `${orderInfo?.doctorData[0]?.firstName} ${orderInfo?.doctorData[0]?.lastName}`}</div>
                            <div style={textSizeWithColor}>Ph: {orderInfo?.doctorData && `${orderInfo?.doctorData[0]?.contact?.countryCode}-${orderInfo?.doctorData[0]?.contact?.mobile}`}</div>
                            <div style={textSizeWithColor} className='pr-5'>Address: {orderInfo?.doctorData && `${orderInfo?.doctorData[0]?.addresses[0]?.address}, ${orderInfo?.doctorData[0]?.addresses[0]?.state}, ${orderInfo?.doctorData[0]?.addresses[0]?.country}`}</div>
                        </div>

                        <div>
                            <div style={textSize} className='font-semibold underline underline-offset-8 pb-4'>Patient Details:</div>
                            <div className='font-bold'>{orderInfo?.patientData && `${orderInfo?.patientData[0]?.firstName} ${orderInfo?.patientData[0]?.lastName}`} </div>
                            <div style={textSizeWithColor} className='flex gap-4 items-center'>
                                <div>ID: {orderInfo?.patientData && orderInfo?.patientData[0]?.uniqueId}</div>
                                <div>|</div>
                                <div>Age: {orderInfo?.patientData && calculateAge(orderInfo?.patientData[0]?.dob)}</div>
                            </div>
                            <div style={textSizeWithColor} className='pr-5'>Address: {orderInfo?.patientData && `${orderInfo?.patientData[0]?.addresses[0]?.address}, ${orderInfo?.patientData[0]?.addresses[0]?.state}, ${orderInfo?.patientData[0]?.addresses[0]?.country}`}</div>
                        </div>

                        <div>
                            <div className='font-semibold underline underline-offset-8 pb-4' style={textSize}>Pharmacy Details:</div>
                            <div className='font-bold'>{orderInfo?.pharmacyData && orderInfo?.pharmacyData[0]?.businessName}</div>
                            <div style={textSizeWithColor}>Ph: {orderInfo?.pharmacyData && `${orderInfo?.pharmacyData[0]?.businessContact?.businessCountryCode}-${orderInfo?.pharmacyData[0]?.businessContact?.businessMobile}`}</div>
                            <div style={textSizeWithColor} className='pr-5'>Address: {orderInfo?.pharmacyData && `${orderInfo?.pharmacyData[0]?.address?.businessAddress}, ${orderInfo?.pharmacyData[0]?.address?.businessState}, ${orderInfo?.pharmacyData[0]?.address?.businessCountry}`}</div>
                        </div>
                    </div>

                    <div className='p-4 mt-5 pb-5'>
                        <div className='font-semibold pb-2' style={textSize}>Medicine Details:</div>
                        <hr className='border' style={{ borderColor: '#A1A1A1' }} />

                        <div>
                            {medicineList && <Table columns={columns} data={medicineList} />}
                        </div>
                    </div>

                    <hr className='border' style={{ borderColor: '#A1A1A1' }} />

                    <div className='py-5 flex justify-end px-4'>
                        {orderInfo?.orderStatus === "COMPLETED" && orderInfo?.paymentStatus === "PAID" &&
                            <div onClick={() => handleViewInvoice(orderInfo?._id, orderInfo)} className='bg-primary px-4 py-2 cursor-pointer text-white rounded-md'>
                                View Invoice
                            </div>
                        }
                    </div>
                </>
                }
            </div>
        </div>
    );
}

export default ViewOrder