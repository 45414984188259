import React, { useState, useContext, useEffect } from "react";
import { HiChevronDoubleRight, HiChevronDoubleLeft } from "react-icons/hi";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../services/Context/UserContext";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import IntakeFormQuestions from '../../utils/intakeQuestionnaire.json'

const NewMedical = () => {
  // document.title = "SignUp - MyHCare Patients";
  document.title = "SignUp - TeleSecure360 Patients";

  //tailwind classes for select input
  // const selectClass = "rounded-lg py-3 px-5 my-3 md:w-96 focus:outline-primary";
  const selectClass = "rounded-lg py-3 px-5 my-3 w-60 focus:outline-primary";

  const disesaseList = [
    "HIV/AIDS",
    "Arthritis",
    "Chronic Pain",
    "nausea",
    "Glaucoma",
    "Anxiety",
    "Cancer",
    "Seizures",
    "Muscle Spasm",
    "Weight Loss",
    "Fibromyalgia",
    "Trouble Sleeping",
    "Loss of Appetite",
    "Migraine Headaches",
    "Other",
  ];
  let [disease, setdisease] = useState([]);
  let navigate = useNavigate();
  const { userInfo, setuserInfo } = useContext(UserContext);
  const { medicalRecord } = userInfo;

  //states
  const [medicalInfo, setmedicalInfo] = useState({});
  const [startDate, setStartDate] = useState(new Date());

  // const [medicalQuestions, setmedicalQuestions] = useState([
  //   {
  //     question:
  //       "Are you RENEWING your certiﬁcation (Have you had a certiﬁcation in the last 10 years)*",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[0].answer : "",
  //   },
  //   {
  //     question:
  //       "Do you currently use speciﬁc medications for your medical condition?",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[1].answer : "",
  //   },
  //   {
  //     question: "Are you taking any prescription medications or herbs?",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[2].answer : "",
  //   },
  //   {
  //     question: "Do you have any allergies to any medications?",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[3].answer : "",
  //   },
  //   {
  //     question: "Have you ever had any surgeries or been hospitalized?",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[4].answer : "",
  //   },
  //   {
  //     question: "Do you exercise?",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[5].answer : "",
  //   },
  //   {
  //     question: "Do you smoke tobacco?",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[6].answer : "",
  //   },
  //   {
  //     question: "Do you drink alcohol?",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[7].answer : "",
  //   },
  //   {
  //     question:
  //       "Are there health/medical problems that occure frequently in your family?",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[8].answer : "",
  //   },
  //   {
  //     question: "Do you have a primary care provider?",
  //     answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[9].answer : "",
  //   },
  //   {
  //     question:
  //       "When was the last time you saw your doctor/specialist about these complaints?",
  //     answer: "",
  //   },
  //   {
  //     question: "Provide details on the medical condition and diagnosis",
  //     answer: userInfo?.medicalRecord?.Questions
  //       ? userInfo?.medicalRecord.Questions[11].answer
  //       : "",
  //   },
  // ]);

  const [medicalQuestions, setmedicalQuestions] = useState([])
  const [intakeQuestions, setIntakeQuestions] = useState([]);
  const [focusedInputIndex, setFocusedInputIndex] = useState(0);

  useEffect(() => {
    if (window.location.href.split('://')[1].startsWith('menthealth')) {
      setIntakeQuestions(IntakeFormQuestions?.businessTypes?.mentalhealth?.intakeQuestionnaire);
    } else if (window.location.href.split('://')[1].startsWith('medhealth')) {
      setIntakeQuestions(IntakeFormQuestions?.businessTypes?.healthcare?.intakeQuestionnaire);
    } else {
      setIntakeQuestions(IntakeFormQuestions?.businessTypes?.marijuna?.intakeQuestionnaire)
    }
    // else if (window.location.href.split('://')[1].startsWith('localhost' || 'patient')) {
    //   setIntakeQuestions(IntakeFormQuestions?.businessTypes?.marijuna?.intakeQuestionnaire)
    // }
  }, [])

  useEffect(() => {
    if (userInfo?.medicalRecord?.Questions) {

      setStartDate(new Date(medicalRecord?.Questions[10]?.answer))
    } else { setStartDate(new Date()) }
  }, [userInfo?.medicalRecord?.Questions])

  //functions and statehandler
  const MedicalInputHandler = (e) => {

    let name = e.target.name;

    // setpersonalInfo({...personalInfo , [name]:e.target.value})

    medicalQuestions.map((item, i) => {
      if (name == item.question) {

        const stateCopy = JSON.parse(JSON.stringify(medicalQuestions));
        stateCopy[i].answer = e.target.value;
        setmedicalQuestions(stateCopy);
      }
    });
  };

  const DiseaseCheck = (e) => {

    if (e.target.checked) {
      setdisease([...disease, e.target.id]);
    }
  };

  const FormSubmit = (e) => {
    e.preventDefault();

    setmedicalInfo({
      ...medicalInfo,
      DiseaseList: disease,
      medicalQuestion: medicalQuestions,
    });

    setuserInfo((prevState) => ({
      ...prevState,
      medicalRecord: {
        DiseaseList: [...disease],
        Questions: medicalQuestions,
      },
    }));
    navigate("/signup/esign", {
      state: {
        DiseaseList: disease,
        Questions: medicalQuestions,
        personal: userInfo.personalRecord,
      },
    });
  };

  // Previous button handler
  const PreviousPage = () => {
    navigate("/signup/personal", { replace: true });
  };

  // useEffect(() => {
  //   let newArr = [...medicalQuestions]; // copying the old datas array
  //   newArr[10].answer = startDate.toISOString().substring(0, 10);

  //   setmedicalQuestions(newArr);
  // }, [startDate]);

  useEffect(() => {
    if (intakeQuestions.length > 0) {
      const updatedQuestions = [...medicalQuestions];
      for (let i = 0; i < intakeQuestions.length; i++) {
        // updatedQuestions[i] = { question: intakeQuestions[i]?.question }
        if (intakeQuestions[i]?.answerType === 'date') {
          updatedQuestions[i] = { question: intakeQuestions[i]?.question, answer: startDate.toISOString().substring(0, 10) }
        }
      }
      setmedicalQuestions(updatedQuestions)
    }
  }, [startDate])

  useEffect(() => {
    if (intakeQuestions.length > 0) {
      const updatedQuestions = [...medicalQuestions];
      for (let i = 0; i < intakeQuestions.length; i++) {
        // updatedQuestions[i] = { question: intakeQuestions[i]?.question }
        if (userInfo?.medicalRecord?.Questions) {
          for (let j = 0; j < userInfo?.medicalRecord?.Questions.length; j++) {
            updatedQuestions[j] = { question: userInfo?.medicalRecord.Questions[j]?.question, answer: userInfo?.medicalRecord?.Questions ? userInfo?.medicalRecord.Questions[j].answer : '' }
          }
        } else {
          // updatedQuestions[i] = { question: intakeQuestions[i]?.question }
          if (intakeQuestions[i]?.answerType === 'date') {
            updatedQuestions[i] = { question: intakeQuestions[i]?.question, answer: startDate.toISOString().substring(0, 10) }
          } else {
            updatedQuestions[i] = { question: intakeQuestions[i]?.question }
          }
        }
      }
      setmedicalQuestions(updatedQuestions)
    }
  }, [intakeQuestions, userInfo?.medicalRecord?.Questions])

  const handleQuestionAnswer = (value, index, question) => {
    const updatedQuestions = [...medicalQuestions];
    updatedQuestions[index] = { question: question, answer: value };
    setmedicalQuestions(updatedQuestions);
  }

  return (
    <>
      <div className="md:mx-32">
        <Header />
        <div className="m-3 -mt-3 rounded-b-lg bg-gray min-h-screen min-w-screen px-3 p-3 text-text md:p-3 md:py-5">
          <form className="md:mx-3" onSubmit={FormSubmit}>
            {/* {window.location.href.split('://')[1].startsWith('localhost') && */}

            <div className={`p-4 mb-4 bg-white`} style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.23)' }}>
              <div className="font-semibold">
                What is/are the main medical problem(s) which you currently have
                or had In the past?
              </div>
              <div className="grid grid-cols-2 mt-5 gap-y-1 md:grid-cols-3 lg:grid-cols-6 md:gap-y-5">
                {disesaseList.map((item, i) => {
                  return (
                    <div key={i}>
                      <input
                        id={item}
                        type="checkbox"
                        defaultChecked={
                          // medicalRecord?.DiseaseList &&
                          // medicalRecord.DiseaseList.includes(item)
                          userInfo?.medicalRecord?.DiseaseList &&
                          userInfo?.medicalRecord.DiseaseList.includes(item)
                        }
                        onChange={DiseaseCheck}
                      />
                      <label className="mx-3">{item}</label>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* } */}
            <div>
              {intakeQuestions?.length > 0 && intakeQuestions.map((data, i) => (
                <div key={i} className={`p-4 mb-4 bg-white ${focusedInputIndex === i ? 'border-l-4 border-primary' : 'border border-Darkgray'}`} style={{ boxShadow: '0 0 4px rgba(0, 0, 0, 0.23)' }}>

                  {data?.answerType === "textbox" &&
                    <div className="relative">
                      <div className="flex gap-2 items-center mb-2">
                        <div className="rounded-full text-white bg-primary w-5 h-5 text-center text-sm">{i + 1}</div>
                        <div className="font-semibold">{data?.question}</div>
                      </div>
                      <div className="mx-7">
                        <input
                          onFocus={() => setFocusedInputIndex(i)}
                          onBlur={() => setFocusedInputIndex(null)}
                          onChange={(e) => handleQuestionAnswer(e.target.value, i, data?.question)}
                          required
                          value={medicalQuestions[i]?.answer}
                          className="outline-none border-b border-Darkgray w-full focus:border-b-2 focus:border-dark transition-all duration-300"
                        />
                      </div>
                    </div>
                  }

                  {data?.answerType === "date" &&
                    <div className="relative">
                      <div className="flex gap-2 items-center mb-2">
                        <div className="rounded-full text-white bg-primary w-5 h-5 text-center text-sm">{i + 1}</div>
                        <div className="font-semibold">{data?.question}</div>
                      </div>
                      <div className="mx-7">
                        <div>
                          <DatePicker
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={50}
                            selected={startDate}
                            // className={selectClass}
                            onChange={(date) => {
                              setStartDate(date);
                            }}
                            required
                            maxDate={new Date()}
                            minDate={userInfo?.personalRecord?.DOB}
                            className="outline-none border-b border-Darkgray w-full focus:border-b-2 focus:border-dark transition-all duration-300"
                          />
                        </div>
                      </div>
                    </div>
                  }

                  {data?.answerType === "checkbox" &&
                    <div>
                      <div className="flex justify-between gap-x-4 gap-y-2 items-center">

                        <div className="flex flex-wrap gap-2">
                          <div className="rounded-full text-white bg-primary w-5 h-5 text-center text-sm">{i + 1}</div>
                          <div className="font-semibold">{data?.question}</div>
                        </div>

                        <div className="flex gap-2">
                          <input required={medicalQuestions[i]?.answer === undefined || medicalQuestions[i]?.answer === ''} id={`yes-${i}`} onFocus={() => setFocusedInputIndex(i)} onBlur={() => setFocusedInputIndex(null)} className="opacity-0" onChange={(e) => handleQuestionAnswer(e.target.value, i, data?.question)} type="radio" name={data?.question} value="Yes" />
                          <label className={`px-4 py-2 rounded-md cursor-pointer ${medicalQuestions[i]?.question === data?.question && medicalQuestions[i]?.answer === 'Yes' ? 'bg-primary text-white' : (medicalQuestions[i]?.answer === 'No' || medicalQuestions[i]?.answer === undefined) && 'bg-primary bg-opacity-20 text-primary'}`} htmlFor={`yes-${i}`}>Yes</label>

                          <input required={medicalQuestions[i]?.answer === undefined || medicalQuestions[i]?.answer === ''} id={`no-${i}`} onFocus={() => setFocusedInputIndex(i)} onBlur={() => setFocusedInputIndex(null)} className="opacity-0" onChange={(e) => handleQuestionAnswer(e.target.value, i, data?.question)} type="radio" name={data?.question} value="No" />
                          <label className={`px-4 py-2 rounded-md cursor-pointer ${medicalQuestions[i]?.question === data?.question && medicalQuestions[i]?.answer === 'No' ? 'bg-danger text-white' : (medicalQuestions[i]?.answer === 'Yes' || medicalQuestions[i]?.answer === undefined) && 'bg-danger bg-opacity-20 text-danger'}`} htmlFor={`no-${i}`}><span>No</span></label>
                        </div>
                      </div>
                    </div>

                    // <div>
                    //   <div className="flex flex-wrap gap-2 items-center">
                    //     <div className="rounded-full text-white bg-primary w-5 h-5 text-center text-sm">{i + 1}</div>
                    //     <div className="font-semibold">{data?.question}</div>

                    //     <input required id={`yes-${i}`} onFocus={() => setFocusedInputIndex(i)} onBlur={() => setFocusedInputIndex(null)} className="opacity-0" onChange={(e) => handleQuestionAnswer(e.target.value, i, data?.question)} type="radio" name={data?.question} value="Yes" />
                    //     <label className={`px-4 py-2 rounded-md cursor-pointer ${medicalQuestions2[i]?.question === data?.question && medicalQuestions2[i]?.answer === 'Yes' ? 'bg-primary text-white' : (medicalQuestions2[i]?.answer === 'No' || medicalQuestions2[i]?.answer === undefined) && 'bg-primary bg-opacity-20 text-primary'}`} htmlFor={`yes-${i}`}>Yes</label>

                    //     <input required id={`no-${i}`} onFocus={() => setFocusedInputIndex(i)} onBlur={() => setFocusedInputIndex(null)} className="opacity-0" onChange={(e) => handleQuestionAnswer(e.target.value, i, data?.question)} type="radio" name={data?.question} value="No" />
                    //     <label className={`px-4 py-2 rounded-md cursor-pointer ${medicalQuestions2[i]?.question === data?.question && medicalQuestions2[i]?.answer === 'No' ? 'bg-danger text-white' : (medicalQuestions2[i]?.answer === 'Yes' || medicalQuestions2[i]?.answer === undefined) && 'bg-danger bg-opacity-20 text-danger'}`} htmlFor={`no-${i}`}><span>No</span></label>
                    //   </div>
                    // </div>
                  }
                </div>
              ))}
            </div>

            <div className="mt-5 flex justify-center space-x-5 md:justify-end">
              <button
                className="flex items-center justify-center px-5 py-3 bg-white border border-primary rounded-lg"
                onClick={PreviousPage}
              >
                <HiChevronDoubleLeft className="text-lg mr-3" />
                Previous{" "}
              </button>
              <button
                type="submit"
                className="flex items-center justify-center px-5 py-3 bg-secondary  rounded-lg text-white"
              >
                Next <HiChevronDoubleRight className="text-lg ml-3" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewMedical;
