import React, { useContext, useState } from "react";
import { HiChevronDoubleRight, HiChevronDoubleLeft } from "react-icons/hi";
import { AiFillCheckCircle } from 'react-icons/ai'
import Header from "../../components/Header/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../services/Context/UserContext";
import { BaseAPI, BaseURL } from "../../services/services";
import { toast } from "react-toastify";
import {
    parsePhoneNumber,
} from "react-phone-number-input";
import Sign from "../../components/Signature Pad";
import { multipleFile, loginAPI } from "../../services/services";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";


const NewEsign = () => {
    // document.title = "SignUp - MyHCare Patients";
    document.title = "SignUp - TeleSecure360 Patients";

    let navigate = useNavigate();
    let { state } = useLocation();
    let { DiseaseList, Questions } = state;

    const { userInfo, setuserInfo } = useContext(UserContext);
    const { personalRecord } = userInfo;

    const [collapse, setCollapse] = useState(false);
    const [text, setText] = useState("Show More");
    const [open, setOpen] = useState(false);

    const [isExpandedPersonalInfo, setIsExpandedPersonalInfo] = useState(true);
    const [isExpandedUploadFiles, setIsExpandedUploadFiles] = useState(true);
    const [isExpandedLiabilityForm, setIsExpandedLiabilityForm] = useState(true);

    const [isExpandedMedicalInfo, setIsExpandedMedicalInfo] = useState(true);


    const toggleExpandPersonalInfo = () => {
        setIsExpandedPersonalInfo(!isExpandedPersonalInfo);
    };

    const toggleExpandMedicalInfo = () => {
        setIsExpandedMedicalInfo(!isExpandedMedicalInfo);
    };

    const toggleExpandUploadFiles = () => {
        setIsExpandedUploadFiles(!isExpandedUploadFiles);
    }

    const toggleExpandLiabilityForm = () => {
        setIsExpandedLiabilityForm(!isExpandedLiabilityForm);
    };


    //getDate for esign
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    //tailwind classes
    const SpanClass =
        "bg-white px-2 py-1 rounded-lg border absolute -top-3 left-5 text-base font-bold";
    const defaultImage =
        "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg";

    //Handle images upload
    const [documents, setdocuments] = useState(null);
    const [medicalDocuments, setMedicalDocuments] = useState([]);



    //collapse , expand terms and conditions
    const expandTermsandCondition = () => {
        setCollapse(!collapse);
        setText(collapse ? "Show More" : "Show Less");
    };

    const fullName =
        personalRecord["First Name"] +
        " " +
        personalRecord["Middle Name"] +
        " " +
        personalRecord["Last Name"];

    // const HandleSign = (e)=>{
    //     
    //     setdocuments({
    //         ...documents,
    //         Esignature:e.target.value
    //     })
    // }

    //hande document upload
    const documentUpload = (e) => {
        let arr = [];

        Object.keys(e.target.files).map((key) => {

            multipleFile(e.target.files[key]).then((result) => {

                let obj;
                obj = {
                    name: e.target.files[key].name,
                    fileLink: result.data.url,
                };
                arr.push(obj);
            });
        });

        setMedicalDocuments(arr);

    };

    // Previous button handler
    const PreviousPage = () => {

        navigate("/signup/medical", { replace: true });
    };

    //Login after signup
    const Login = (email, password) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            email: email,
            password: password,
            deviceType: 3,
            deviceToken: "string",
            uuid: localStorage.getItem("uuid"),
            role: 2,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        loginAPI(requestOptions).then(async (result) => {

            if (result.status == "200") {
                setuserInfo({
                    ...userInfo, auth: true
                })
                localStorage.setItem("userData", JSON.stringify(result.data));
                localStorage.setItem("token", result.data.token);
                if (result.data.isPasswordToBeChanged) {
                    toast.warn("Your password is expired. Please change your password");
                    setTimeout(() => {
                        navigate("/forgot");
                    }, 2000);
                } else if (result.data.isTwoFactorActuallyRequired) {
                    navigate("/twoFactor");
                    localStorage.setItem("Authenicated", true);
                } else {
                    toast.success(result.message, {
                        icon: <AiFillCheckCircle className="text-secondary text-lg" />
                    });
                    setTimeout(() => {
                        navigate("/home/home");
                    }, 3000);
                }
            } else {
                toast.warn(result.message);
            }
        });
    };

    //
    const SubmitSignup = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            firstName: personalRecord["First Name"],
            middleName: personalRecord["Middle Name"],
            lastName: personalRecord["Last Name"],
            email: personalRecord.Email,
            password: personalRecord.Password,
            confirmPassword: personalRecord["Confirm Password"],
            countryCode:
                "+" +
                parsePhoneNumber(personalRecord["Phone Number"]).countryCallingCode, //cehck country code
            mobile: parsePhoneNumber(personalRecord["Phone Number"]).nationalNumber,
            timeZone: personalRecord.Timezone,
            dob: personalRecord.DOB.toISOString().substring(0, 10),
            address: personalRecord.address,
            role: 2,
            longitude: personalRecord.long,
            latitude: personalRecord.lat,
            gender: personalRecord.Gender,
            stateForDocEvaluation: personalRecord["State for evaluation"],
            medicalProblems: DiseaseList,
            medicalQuestions: Questions,
            eSignature: documents.url,
            deviceType: 3,
            profilePic: {
                original: personalRecord?.profile.url,
                thumbnail: personalRecord?.profile.thumbUrl,
            },
            documents: medicalDocuments,
            city: personalRecord.City,
            state: personalRecord.State,
            zipCode: personalRecord.ZipCode,
            country: personalRecord.Country,
            timeZoneId: personalRecord.timeZoneId,
            timeZoneName: personalRecord.timeZoneName,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        BaseAPI(`${BaseURL}/users/signUp`, requestOptions).then((result) => {

            if (result.status != 200) {
                toast.warn(result.message);
            } else {
                toast.success(result.message, {
                    icon: <AiFillCheckCircle className="text-secondary text-lg" />
                });
                Login(personalRecord.Email, personalRecord.Password);
            }
        });
    };

    const checkSign = () => {
        if (documents) {
            SubmitSignup();
        } else toast.warn("Please sign the document");
    };

    return (
        <>
            <div className="md:mx-32">
                <Header />
                <div className="m-3 -mt-3 bg-gray rounded-lg min-h-screen min-w-screen p-2 text-text">


                    <h2 className="font-semibold mb-5 md:px-5 mt-3">
                        PLEASE REVIEW AND E-SIGN THE MEDICAL FORM YOU HAVE JUST COMPLETED:
                    </h2>

                    <div className={`flex justify-between mx-3 p-4 bg-primary bg-opacity-20 ${isExpandedPersonalInfo ? 'rounded-t-md' : 'rounded-md'}`}>
                        <div className="font-semibold">PERSONAL INFORMATION</div>
                        <div className="cursor-pointer" onClick={toggleExpandPersonalInfo}>
                            {isExpandedPersonalInfo ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                    </div>

                    {isExpandedPersonalInfo &&
                        <div className="mx-3 p-4 pb-10 rounded-b-md bg-white">
                            <img
                                src={personalRecord?.profile?.url ? personalRecord?.profile?.url : defaultImage}
                                className="h-20 w-20 mt-2 rounded-full object-cover"
                                alt="user"
                            />

                            <div className="grid gap-5 md:grid-cols-4 grid-cols-1 md:gap-x-10 mt-5">
                                {Object.keys(personalRecord).map((key, i) => {
                                    if (
                                        key !== "Password" &&
                                        key !== "long" &&
                                        key !== "lat" &&
                                        key !== "Confirm Password" &&
                                        key !== "profile" &&
                                        key !== "Timezone" &&
                                        key !== "timeZoneId" &&
                                        key !== "timeZoneName"
                                    ) {
                                        if (key === "DOB") {
                                            return (
                                                <div key={i}>
                                                    <h1 className="font-semibold">{key}:</h1>
                                                    <p> {personalRecord[key].toDateString()} </p>
                                                </div>
                                            );
                                        } else
                                            return (
                                                <div key={i}>
                                                    <h1 className="font-semibold">{key}:</h1>
                                                    <p> {personalRecord[key]} </p>
                                                </div>
                                            );
                                    }
                                })}
                            </div>
                        </div>
                    }

                    <div className={`flex justify-between mx-3 mt-3 p-4 bg-primary bg-opacity-20 ${isExpandedMedicalInfo ? 'rounded-t-md' : 'rounded-md'}`}>
                        <div className="font-semibold">MEDICAL</div>
                        <div className="cursor-pointer" onClick={toggleExpandMedicalInfo}>
                            {isExpandedMedicalInfo ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                    </div>

                    {isExpandedMedicalInfo &&
                        <div className="mx-3 p-4 pb-10 rounded-b-md bg-white">
                            <div className="grid gap-y-5 grid-cols-1 mt-5 p-3 md:grid-cols-2 md:gap-x-10">
                                <div>
                                    <h5 className="font-bold">
                                        What is/are the main medical problem(s) which you currently
                                        have or have had in the past ? (HIV/AIDS, Arthritis, Chronic
                                        Pain, Loss of Appetite, nausea, Muscle Spasm, Glaucoma, Weight
                                        Loss, Fibromyalgia, Migraine Headaches,Cancer, Seizures,
                                        Trouble Sleeping, Anxiety, or Other) :
                                    </h5>
                                    <p>
                                        {DiseaseList.map((item) => {
                                            return item + ",";
                                        })}
                                    </p>
                                </div>

                                {Questions.map((key, i) => {
                                    if (key !== "DiseaseList")
                                        return (
                                            <div className="my-5" key={i}>
                                                <h1 className="font-bold">{key.question}:</h1>
                                                <p> {key.answer} </p>
                                            </div>
                                        );
                                })}
                            </div>
                        </div>
                    }

                    <div className={`flex justify-between mx-3 mt-3 p-4 bg-primary bg-opacity-20 ${isExpandedUploadFiles ? 'rounded-t-md' : 'rounded-md'}`}>
                        <div className="font-semibold">PREVIOUS CERTIFICATION OR MEDICAL FILES</div>
                        <div className="cursor-pointer" onClick={toggleExpandUploadFiles}>
                            {isExpandedUploadFiles ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                    </div>

                    {isExpandedUploadFiles &&
                        <div className="mx-3 p-4 pb-10 rounded-b-md bg-white">
                            <div className="p-3">
                                <p>
                                    If available, please upload your Previous certification or any
                                    other medical record as related medical condition(s) identified
                                    above? (example files medical record).:
                                </p>
                                <input
                                    type="file"
                                    className="mt-3"
                                    multiple
                                    onChange={(e) => {
                                        documentUpload(e);
                                    }}
                                />
                                <div className="space-y-4 mt-5">
                                    <p>
                                        If you CANNOT upload your medical record copies, then please
                                        provide the following information:
                                    </p>
                                    <p>Provide your details here</p>
                                    <input
                                        className="border focus:outline-primary px-3 py-2 rounded-lg"
                                        placeholder="enter details here"
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    <div className={`flex justify-between mx-3 mt-3 p-4 bg-primary bg-opacity-20 ${isExpandedLiabilityForm ? 'rounded-t-md' : 'rounded-md'}`}>
                        <div className="font-semibold">INFORMED CONSENT AND RELEASE OF LIABILITY FORM</div>
                        <div className="cursor-pointer" onClick={toggleExpandLiabilityForm}>
                            {isExpandedLiabilityForm ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                    </div>
                    {isExpandedLiabilityForm &&
                        <div className="mx-3 p-4 pb-10 rounded-b-md bg-white">
                            Please read each item below and initial in the space provided to
                            indicate you understand the information regarding the risks and side
                            effects of using marijuana. I agree to tell the attending physician
                            if I do not understand any of the information provided. <br />
                            Patient Initials the following: <br />
                            <ul className="flex flex-col gap-3">
                                <li>
                                    {" "}
                                    I understand that the cultivation, possession and use of
                                    marijuana, even for medical purposes, are currently ILLEGAL
                                    UNDER FEDERAL LAW.
                                </li>
                                <li>
                                    {" "}
                                    Under federal and state law, it is not permitted to use or
                                    possess marijuana within 1000 feet of a daycare or school. I
                                    agree not to do so.
                                </li>
                                <li>
                                    {" "}
                                    I agree not to use marijuana while under the influence of
                                    alcohol.
                                </li>
                                <li>
                                    I agree to tell the attending physician if I have ever had
                                    symptoms of depression, been psychotic, attempted suicide or had
                                    any other mental problems.
                                </li>
                                <li>
                                    I also agree to tell the attending physician if I have ever been
                                    prescribed or taken medicine for any of the conditions stated
                                    above. Furthermore, I understand that the attending physician
                                    does not suggest nor condone that I cease treatment and/or
                                    medication that stabilize my mental or physical condition
                                </li>
                                <li>I agree to immediately stop using marijuana  while under the influence of alcohol.</li>
                                {collapse && <div className="flex flex-col gap-3">
                                    <li>I understand that it is against the law to drive a vehicle while using marijuana and that doing so will result in a DUI for driving under the influence</li>
                                    <li>I agree to tell the attending physician if I have ever had symptoms of depression, been psychotic, attempted suicide or had any other mental problems. I also agree to tell the attending physician if I have ever been prescribed or taken medicine for any of the conditions stated above. Furthermore, I understand that the attending physician does not suggest nor condone that I cease treatment and/or medication that stabilize my mental or physical condition</li>
                                    <li>I agree to immediately stop using marijuana and inform the physician in the event I become suicidal, homicidal, paranoid or have an increase in any of these symptoms.</li>
                                    <li>There are few known interactions between marijuana and medications or other herbs. However, very few interactions between herbs and medications have been studied. I agree to tell my attending physician if I am using any herbs, supplements or other medications</li>
                                    <li>Some users might develop a tolerance to marijuana. This means higher and higher does are required to achieve the same clinical effect. It is recommended for doctors to have an intermission with drug for at least 3 weeks every 3-4 months. If I think I may be developing a tolerance to marijuana. I will notify my attending physician. </li>
                                    <li>Should respiratory problems or other ill effects be experienced in association with the use of medical marijuana, I agree to discontinue its use and report any or report such problems or effects to the attending physician. Although smoking marijuana has not been linked to lung cancer, smoking marijuana can cause respiratory harm, such as bronchitis. Many researchers agree that marijuana smoke contains known carcinogens (chemicals that can cause cancer), and that smoking marijuana may increase the risk of respiratory diseases and cancers of the lungs, mouth and tongue. I have been advised that cannabis (medical marijuana) smoke contain chemicals known as tars that may be harmful to my health. Vaporizers may substantially reduce many of the potentially harmful smoke and toxins that are normally present in marijuana smoke.</li>
                                    <li>Marijuana varies in potency. The effects of marijuana can also vary with the delivery system. Estimating the proper marijuana dosage is very important. Symptoms of marijuana overdose include, but are not limited to nausea, vomiting, hacking cough, disturbances to heart rhythms and numbness in the limbs.</li>
                                    <li>The efficacy and potency of marijuana varies widely depending on the marijuana strain and ingestion method. Under federal law, the attending physician is unable to discuss dosage.</li>
                                    <li>I am not currently pregnant. In the event I become pregnant, I agree to immediately discontinue use of marijuana and contact the physician. (Females only)</li>
                                    <li>Cannabis is not regulated by the USFDA and therefore may contain unknown quantities of active ingredients, impurities and or contaminants.</li>
                                </div>}
                            </ul>
                            <div className="cursor-pointer flex justify-center md:justify-end text-primary font-bold text-base">
                                <span onClick={expandTermsandCondition}>{text}</span>
                            </div>
                        </div>
                    }

                    <div className="mt-5 mx-5">
                        <h2 className="font-bold">
                            I, {fullName}, confirm that the information provided by me
                            regarding my diagnosis and medical records, is true and correct.
                        </h2>

                        <div className="flex flex-col md:flex-row-reverse md:justify-between">
                            <div className="flex flex-col justify-center items-center mt-5">
                                {documents?.thumbUrl && <img src={documents?.thumbUrl} alt='sign' />}
                                <p className="cursor-pointer" onClick={() => setOpen(true)}>
                                    Upload Signature
                                </p>
                                <Sign open={open} close={setOpen} setEsign={setdocuments} />
                            </div>

                            <div className="flex justify-between md:flex-col md:space-y-3 mt-10">
                                <div>
                                    <h5>Signed By</h5>
                                    <span>
                                        {personalRecord["First Name"] != null && fullName}
                                    </span>
                                </div>

                                <div>
                                    <h5>Signed On</h5>
                                    <span>{today}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-evenly md:justify-end md:space-x-5 mr-5 text-base">
                        <button
                            className="flex align-center justify-center bg-white rounded-lg px-3 py-2 border"
                            onClick={PreviousPage}
                        >
                            <HiChevronDoubleLeft className="text-lg" />
                            Previous
                        </button>
                        <button
                            onClick={checkSign}
                            className="flex align-center justify-center rounded-lg px-3 py-2 bg-secondary text-white"
                        >
                            Submit <HiChevronDoubleRight className="text-lg" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewEsign;
