import React, { useState, useEffect, useMemo, useRef } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Table from "../../components/Table/Table";
import { BaseAPI, BaseURL } from "../../services/services";
import { timeConvert, } from "../../services/Constants";
import moment from 'moment'
import Spinner from "../../components/Loader/Spinner";
import { AiOutlineDownload, AiOutlineEye, AiOutlineSearch } from "react-icons/ai";
import { HiOutlineX } from "react-icons/hi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Order = () => {
    const [orderData, setOrderData] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [searchText, setsearchText] = useState("");

    let searchRef = useRef("");

    const navigate = useNavigate();

    const user = localStorage.getItem("userData");
    const token = localStorage.getItem("token");
    const patientId = JSON.parse(user)._id;

    const HandleSearch = (e) => {
        setsearchText(e.target.value);
    };

    const myHeaders = new Headers({
        accept: "application/json",
        authorization: `${token}`,
        "Content-Type": "application/json",
    });

    const getOrderList = () => {
        setisLoading(true);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        BaseAPI(
            `${BaseURL}/doctor/getMedicineOrders?patientId=${patientId}`,
            // `${BaseURL}/doctor/getMedicineOrders`,
            requestOptions
        )
            .then((result) => {
                if (result.status !== 200) {
                    toast.error(result.message);
                    setTimeout(() => {
                        navigate("/");
                    }, 3000);
                } else {
                    setOrderData(result.data);
                }
                setisLoading(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error("Something went wrong!");
            })
            .finally(() => setisLoading(false));
    };

    const viewAppointmentDetails = (data) => {
        navigate("/home/order/view", { state: { id: data?._id } });
        // navigate("/home/order/view");
    };

    useEffect(() => {
        getOrderList();
    }, []);

    const getPdf = (id) => {
        let myHeaders = new Headers({
            accept: "application/json",
            authorization: `${token}`,
        });

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        return fetch(
            `${BaseURL}/doctor/getPdf?id=${id}`,
            requestOptions
        )
    }

    const handleViewInvoice = (id, data) => {
        getPdf(data?.invoiceId)
            .then((res) =>
                res.arrayBuffer()
            )
            .then(res => {
                const file = new Blob([res], { type: 'application/pdf' })
                const fileURL = URL.createObjectURL(file)
                window.open(fileURL)
            })
            .catch(err => console.log(err))
    }

    const data = useMemo(() => orderData?.orders, [orderData]);

    const columns = useMemo(
        () => [
            {
                Header: "Order ID",
                accessor: 'uniqueId',
                Cell: ({ row }) => (<div>{row.original.uniqueId}</div>),
            },
            {
                Header: "Appointment ID",
                accessor: 'appointmentData[0].bookingId',
                Cell: ({ row }) => (<div>{row.original?.appointmentData[0]?.bookingId}</div>),
            },
            {
                Header: "Doctor ID",
                accessor: 'doctorData[0].uniqueId',
                Cell: ({ row }) => (<div>{row.original.doctorData[0]?.uniqueId}</div>),
            },
            {
                Header: "Doctor Name",
                accessor: 'doctorData[0].firstName',
                Cell: ({ row }) => (<div>{row.original.doctorData[0]?.firstName} {row.original.doctorData[0]?.lastName}</div>),
            },
            {
                Header: "Pharmacy Name",
                accessor: 'pharmacyData[0].businessName',
                Cell: ({ row }) => (<div>{row.original?.pharmacyData[0]?.businessName}</div>),
            },
            {
                Header: "Order Date",
                accessor: 'createdAt',
                Cell: ({ row }) => (<div>{moment(row.original.createdAt.slice(0, 10)).format('MMMM DD,YYYY')}</div>),
            },
            // {
            //     Header: "Amount",
            //     accessor: 'paymentAmount',
            //     Cell: ({ row }) => (<div>{row.original?.paymentAmount} $</div>),
            // },
            {
                Header: "Order Status",
                accessor: 'orderStatus',
                Cell: ({ row }) => (<div>{row.original.orderStatus}</div>),
            },
            {
                Header: 'Payment Status',
                accessor: 'paymentStatus',
                // Cell: ({ row }) => (<div>{row.original?.paymentStatus ? row.original?.paymentStatus : 'UNPAID'}</div>),
                Cell: ({ row }) => (<div>{row.original?.paymentStatus}</div>),
            },
            {
                Header: "Actions",
                Cell: ({ row }) => (
                    <div className="flex items-center space-x-5 text-lg">
                        <span
                            title="View"
                            className="cursor-pointer"
                            onClick={() => viewAppointmentDetails(row.original)}
                        >
                            <AiOutlineEye />
                        </span>
                        {row.original?.orderStatus === "COMPLETED" && row.original?.paymentStatus === "PAID" &&
                            <span className="cursor-pointer" onClick={() => handleViewInvoice(row.original?._id, row.original)} title="Download Invoice"> <AiOutlineDownload /></span>
                        }
                    </div>
                ),
            },
        ],
        [orderData?.orders]
    );

    return (
        <div className="Home bg-Light-gray min-h-screen w-full">
            <Navbar heading="Home / Order" />
            <div className="flex justify-between items-center w-full py-5 px-4 flex-col md:flex-row space-y-3 md:space-y-0">
                <div>
                    <h1 className="text-lg font-bold text-text">My Orders</h1>
                </div>
                <div className="flex space-x-2 md:space-x-10 px-3 md:px-0">
                    <div className="relative">
                        <input
                            type="search"
                            ref={searchRef}
                            className="rounded-lg  px-4 pl-8 py-3 shadow text-sm"
                            placeholder="Search"
                            onChange={HandleSearch}
                        />
                        <span className="absolute top-3.5 text-base text-text left-1">
                            <AiOutlineSearch />
                        </span>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) : (
                orderData && (
                    <Table
                        className="bg-white mx-3"
                        filter={searchText}
                        columns={columns}
                        data={data}
                        pageSize={10}
                    />
                )
            )}

        </div>
    );
};

export default Order;
