import React, { useState, useMemo, lazy } from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";
import LoginForm from "../pages/Login/LoginForm";
import Newpass from "../pages/Login/Newpass";
import ForgotPass from "../pages/Login/ForgotPass";
import Personal from "../pages/Signup/Personal";
import Medical from "../pages/Signup/Medical";
import Esign from "../pages/Signup/Esign";
import Patient from "../pages/Dashboard/Patient";
import Home from "../pages/Dashboard/Home";
import Settings from "../pages/Dashboard/Settings";
import Appointment from "../pages/Dashboard/Appointment";
import Profile from "../pages/Dashboard/Profile";
import DoctorProfile from "../pages/Dashboard/DoctorProfile";
import Details from "../pages/Dashboard/Details";
import Documents from "../pages/Dashboard/Documents/Documents";
import Payment from "../pages/Dashboard/Payment/Payment";
import PaymentSuccess from "../pages/Dashboard/Payment/PaymentConfirmation/PaymentSuccess";
import Session from "../pages/Dashboard/Session";
import TwoFactorAuth from "../pages/Login/TwoFactorAuth";
import View from "../pages/Dashboard/AppointmentView/View";
import Terms from "../pages/misc/Terms";
import Recommendation from "../pages/Dashboard/Recommendation";
import Example from "../pages/Dashboard/Payment/Example";
import { UserContext } from "../services/Context/UserContext";
import Prescription from "../pages/Dashboard/Prescriptions";
import MedicalData from "../pages/Dashboard/clinical";
import ViewRating from "../pages/Dashboard/ViewRating";
import NewPersonal from "../pages/Signup/NewPersonal";
import NewMedical from "../pages/Signup/NewMedical";
import NewEsign from "../pages/Signup/NewEsign";
import IntakeFormQuestions from '../utils/intakeQuestionnaire.json'
import NewHomePage from "../pages/Dashboard/NewHomepage";
import Order from "../pages/Dashboard/Order";
import ViewOrder from "../pages/Dashboard/ViewOrder";

// const Prescription = lazy(() => import("../pages/Dashboard/Presciptions/index.js"))

const Routes = () => {
  const [userInfo, setuserInfo] = useState({
    auth: localStorage.getItem('token') ? true : false,
    personalRecord: {
      "First Name": "",
      "Middle Name": "",
      "Last Name": "",
      Gender: "none",
      "Phone Number": "",
      profile: "",
      DOB: "",
      Email: "",
      Password: "",
      "Confirm Password": "",
      Address: "",
      Timezone: "none",
      State: "",
      long: 75.26589,
      lat: 34.98765,
    },
    medicalRecord: {
      // medicalQuestions: [
      //   {
      //     question:
      //       "Are you RENEWING your certiﬁcation (Have you had a certiﬁcation in the last 10 years)*",
      //     answer: "yes",
      //   },
      //   {
      //     question:
      //       "Do you currently use speciﬁc medications for your medical condition?",
      //     answer: "yes",
      //   },
      //   {
      //     question: "Are you taking any prescription medications or herbs?",
      //     answer: "yes",
      //   },
      //   {
      //     question: "Do you have any allergies to any medications?",
      //     answer: "yes",
      //   },
      //   {
      //     question: "Have you ever had any surgeries or been hospitalized?",
      //     answer: "yes",
      //   },
      //   {
      //     question: "Do you exercise?",
      //     answer: "yes",
      //   },
      //   {
      //     question: "Do you smoke tobacco?",
      //     answer: "yes",
      //   },
      //   {
      //     question: "Do you drink alcohol?",
      //     answer: "yes",
      //   },
      //   {
      //     question:
      //       "Are there health/medical problems that occure frequently in your family?",
      //     answer: "yes",
      //   },
      //   {
      //     question: "Do you have a primary care provider? ",
      //     answer: "yes",
      //   },
      //   {
      //     question:
      //       "When was the last time you saw your doctor/specialist about these complaints?",
      //     answer: "",
      //   },
      //   {
      //     question: "Provide details on the medical condition and diagnosis",
      //     answer: "",
      //   },
      // ],

      // medical question based on url
      medicalQuestions: window.location.href.split('://')[1].startsWith('menthealth')
        ? IntakeFormQuestions?.businessTypes?.mentalhealth?.intakeQuestionnaire?.map((quest) => ({ question: quest?.question, answer: '' }))
        : window.location.href.split('://')[1].startsWith('medhealth')
          ? IntakeFormQuestions?.businessTypes?.healthcare?.intakeQuestionnaire?.map((quest) => ({ question: quest?.question, answer: '' }))
          // : window.location.href.split('://')[1].startsWith('localhost' || 'patient') && IntakeFormQuestions?.businessTypes?.marijuna?.intakeQuestionnaire?.map((quest) => ({ question: quest?.question, answer: '' }))
          : IntakeFormQuestions?.businessTypes?.marijuna?.intakeQuestionnaire?.map((quest) => ({ question: quest?.question, answer: '' }))
    },
  });
  const ProviderValue = useMemo(
    () => ({ userInfo, setuserInfo }),
    [userInfo, setuserInfo]
  );



  return (
    <Switch>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route
        path="/login"
        element={
          <UserContext.Provider value={ProviderValue}>
            <LoginForm />
          </UserContext.Provider>
        }
      />
      <Route path="/forgot" element={<ForgotPass />} />
      <Route path="/resetPassword" element={<Newpass />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/twoFactor" element={<TwoFactorAuth />} />
      <Route path="/signup">
        <Route
          path="personal"
          element={
            <UserContext.Provider value={ProviderValue}>
              {/* <Personal /> */}
              {/* Prosonal info form with new UI changes */}
              <NewPersonal />
            </UserContext.Provider>
          }
        />
        <Route
          path="medical"
          element={
            <UserContext.Provider value={ProviderValue}>
              {/* <Medical /> */}
              {/* Medical form with new UI changes and url based question */}
              <NewMedical />
            </UserContext.Provider>
          }
        />
        <Route
          path="esign"
          element={
            <UserContext.Provider value={ProviderValue}>
              {/* <Esign /> */}
              {/* E-sign form with new UI changes */}
              <NewEsign />
            </UserContext.Provider>
          }
        />
      </Route>
      <Route path="home" element={<Patient />}>
        {/* this routes is for login as guest  */}
        <Route path="home" element={<Home />} />
        {/* <Route path="home" element={<NewHomePage />} /> */}
        <Route path="DoctorProfile" element={<DoctorProfile />} />
      </Route>
      {userInfo.auth ? (
        <Route path="home" element={<Patient />}>
          <Route path="home" element={<Home />} />
          {/* <Route path="home" element={<NewHomePage />} /> */}
          <Route path="details" element={<Details />} />
          <Route path="DoctorProfile" element={<DoctorProfile />} />
          <Route path="settings" element={<Settings />} />
          <Route path="appointment" element={<Appointment />} />
          <Route path="appointment/view" element={<View />} />
          <Route path="order" element={<Order />} />
          <Route path="order/view" element={<ViewOrder />} />
          <Route path="appointment/doc" element={<Recommendation />} />
          <Route path="profile" element={<Profile />} />
          {/* temporary disbale prescriptions/invoice section */}
          {/* <Route path="prescriptions" element={<Prescription />} /> */}
          <Route path="paymentOption" element={<Payment />} />
          <Route path="success" element={<PaymentSuccess />} />
          <Route path="documents" element={<Documents />} />
          <Route path="session" element={<Session />} />
          <Route path="clinical" element={<MedicalData />} />
          <Route path="example" element={<Example />} />
          <Route path="appointment/rating" element={<ViewRating />} />
        </Route>
      ) : (
        <Route path="/login" element={<Navigate to="/login" />} />
      )}
      <Route path="*" element={<Navigate to="/login" />} />
    </Switch>
  );
};

export default Routes;
