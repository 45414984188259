import React, { useState, useContext, useEffect } from "react";
import { HiChevronDoubleRight } from "react-icons/hi";
import Header from "../../components/Header/Header";
import { useNavigate, Link } from "react-router-dom";
import { Country, State, City } from 'country-state-city';
import { UserContext } from "../../services/Context/UserContext";
import { validateEmail, strongPassword } from "../../services/Constants";
import {
    AiOutlineEye,
    AiOutlineEyeInvisible,
} from "react-icons/ai";
import { MdAddAPhoto } from "react-icons/md";
import Autocomplete from "../../components/PlacesAutocomplete/Autocomplete";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ImageUpload } from "../../services/services";
import { States, Countries } from "../../services/Constants";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import Spin from "../../components/Loader/Spin";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const NewPersonal = () => {
    // document.title = "SignUp - MyHCare Patients";
    document.title = "SignUp - TeleSecure360 Patients";
    const { userInfo, setuserInfo } = useContext(UserContext);


    //tailwind Classes
    const InputClass =
        // "border bg-white border-primary px-3 py-2 min-w-full rounded-lg focus:outline-primary";
        "border bg-white border-primary pl-3 py-2 min-w-full rounded-lg focus:outline-primary";
    const selectClass = "bg-white px-3 py-2 w-full rounded-lg focus:outline-none";
    const errorClass = "text-sm text-danger";

    // states
    let navigate = useNavigate();
    const [numvalue, setValue] = useState(
        userInfo.personalRecord["Phone Number"]
    );
    const [startDate, setStartDate] = useState(new Date());
    const [profilePic, setprofilePic] = useState({
        url: "",
        thumbUrl: "",
    });
    const [personalInfo, setpersonalInfo] = useState({
        "First Name": userInfo.personalRecord["First Name"],
        "Middle Name": userInfo.personalRecord["Middle Name"],
        "Last Name": userInfo.personalRecord["Last Name"],
        Gender: userInfo.personalRecord.Gender,
        "Phone Number": userInfo.personalRecord["Phone Number"],
        profile: userInfo.personalRecord["profile"] || profilePic,
        DOB: userInfo.personalRecord.DOB,
        Email: userInfo.personalRecord.Email,
        Password: userInfo.personalRecord.Password,
        "Confirm Password": userInfo.personalRecord["Confirm Password"],
        address: userInfo.personalRecord.address,
        Timezone: userInfo.personalRecord.Timezone,
        // long: 75.26589,
        // lat: 34.98765,
        long: 75.26589,
        lat: 34.98765,
        State: userInfo.personalRecord.State,
        "State for evaluation":
            userInfo?.personalRecord["State for evaluation"] || "",
        City: userInfo?.personalRecord?.City ? userInfo?.personalRecord?.City : "",
        ZipCode: userInfo?.personalRecord?.ZipCode
            ? userInfo?.personalRecord?.ZipCode
            : "",
        Country: userInfo?.personalRecord?.Country
            ? userInfo?.personalRecord?.Country
            : "",
        timeZoneName: userInfo?.personalRecord?.timeZoneName ? userInfo?.personalRecord?.timeZoneName : "",
    });

    const [validation, setvalidations] = useState({
        "First Name": "",
        "Middle Name": "",
        "Last Name": "",
        Gender: "",
        DOB: "",
        Email: "",
        Password: "",
        "Confirm Password": "",
        Timezone: "",
        State: "",
    });

    const [validPhone, setValidPhone] = useState("");

    const [isExpandedPersonalInfo, setIsExpandedPersonalInfo] = useState(true);
    const [isExpandedAddressInfo, setIsExpandedAddressInfo] = useState(true);
    const [isExpandedEvaluation, setIsExpandedEvaluation] = useState(true);

    const [hidePassword, sethidePassword] = useState({
        password: false,
        confirmPassword: false,
    });

    const [searchLength, setsearchLength] = useState({
        byName: 0,
        byPlace: 0,
    });

    const [isUploading, setIsUploading] = useState(false);

    const toggleExpandPersonalInfo = () => {
        setIsExpandedPersonalInfo(!isExpandedPersonalInfo);
    };

    const toggleExpandAddressInfo = () => {
        setIsExpandedAddressInfo(!isExpandedAddressInfo);
    };

    const toggleExpandEvaluation = () => {
        setIsExpandedEvaluation(!isExpandedEvaluation);
    };

    useEffect(() => {
        if (userInfo.personalRecord.DOB) {
            setStartDate(new Date(userInfo.personalRecord.DOB))
        }
    }, [userInfo.personalRecord.DOB])

    const ValidateInput = (id, value) => {
        switch (id) {
            case "First Name":
                if (/\d/.test(value)) {

                    setvalidations({ ...validation, [id]: "No number allowed" });
                } else setvalidations({ ...validation, [id]: "" });
                break;

            case "Middle Name":
                if (/\d/.test(value)) {

                    setvalidations({ ...validation, [id]: "No number allowed" });
                } else setvalidations({ ...validation, [id]: "" });
                break;

            case "Country":
                if (/\d/.test(value)) {

                    setvalidations({ ...validation, [id]: "Invalid Country" });
                } else setvalidations({ ...validation, [id]: "" });
                break;

            case "Last Name":
                if (/\d/.test(value)) {

                    setvalidations({ ...validation, [id]: "No number allowed" });
                } else setvalidations({ ...validation, [id]: "" });

                break;

            case "Email":
                if (!validateEmail(value)) {

                    setvalidations({ ...validation, [id]: "Invalid email" });
                } else setvalidations({ ...validation, [id]: "" });
                break;

            case "Password":
                if (value.length < 8) {
                    setvalidations({
                        ...validation,
                        [id]: "Password must be atleast 8 characters",
                    });
                } else if (!strongPassword(value)) {

                    setvalidations({
                        ...validation,
                        [id]: "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character",
                    });
                } else setvalidations({ ...validation, [id]: "" });
                break;

            case "Confirm Password":
                if (value !== personalInfo["Password"]) {

                    setvalidations({ ...validation, [id]: "Passwords must be same" });
                } else setvalidations({ ...validation, [id]: "" });
                break;

            case "DOB":
                if (startDate.getTime() > new Date().getTime()) {

                    setvalidations({ ...validation, [id]: "Invalid Date" });
                } else setvalidations({ ...validation, [id]: "" });
                break;

            case "Bio":
                if (value.length < 3) {

                    setvalidations({ ...validation, [id]: "Required" });
                } else setvalidations({ ...validation, [id]: "" });
                break;

            case "ZipCode":
                var regExp = /[a-zA-Z]/g;

                if (regExp.test(value)) {
                    setvalidations({
                        ...validation,
                        [id]: "Zip Code should be in digit",
                    });
                } else setvalidations({ ...validation, [id]: "" });
                break;
            default:
                break;
        }
    };

    //function and stateHandlers

    const UploadProfile = (e) => {

        // ImageUpload(e).then((result) => {

        //   setprofilePic(result.data);
        // });
        // setpersonalInfo(() => ({ ...personalInfo, profile: profilePic }));

        setIsUploading(true);
        ImageUpload(e).then((result) => {

            setprofilePic(result.data);
        }).finally(() => { setIsUploading(false) });
        setpersonalInfo(() => ({ ...personalInfo, profile: profilePic }));
    };

    const timezonesList = [
        { name: 'Eastern Standard Time', value: '1' },
        { name: 'Central Standard Time', value: '2' },
        { name: 'Pacific Standard Time', value: '3' },
        { name: 'Mountain Standard Time', value: '4' },
        { name: 'Hawaii Standard Time', value: '5' },
        { name: 'Alaska Standard Time', value: '6' },
        { name: 'Indian Standard Time', value: '7' },
        { name: 'South African Standard time', value: '8' },
    ];

    const InputHandler = (e) => {
        let name = e.target.id;
        let value = e.target.value;
        if (name != "Phone Number") {
            setpersonalInfo({ ...personalInfo, [name]: e.target.value });
            ValidateInput(name, e.target.value);
        }

        if (name === "Timezone") {
            const lat = value === '1' ? 25.76905 : value === '2' ? 41.8860775 : value === '3' ? 33.7985879 : value === '4' ? 51.0219537 : value === '7' ? 30.7364136 : value === '8' ? -29.1181113 : 25.76905;
            const long = value === '1' ? -80.132234 : value === '2' ? -87.6379001 : value === '3' ? -117.9267073 : value === '4' ? -114.0265503 : value === '7' ? 76.6583503 : value === '8' ? 25.6322328 : -80.132234;

            const selectedTimezone = timezonesList.find(tz => tz.value === value);
            if (selectedTimezone) {
                setpersonalInfo({
                    ...personalInfo,
                    [name]: e.target.value,
                    timeZoneName: selectedTimezone.name,
                    long: long,
                    lat: lat,
                });
            }
        }

    };

    const CheckFormValid = () => {
        let valid = true;
        let arr = [];
        for (let key in validation) {
            if (validation[key] != "") {
                valid = false;
                arr = [...arr, key];
            }
        }
        if (validPhone !== "") {
            valid = false;
        }

        if (valid) {
            navigate("/signup/medical", { state: personalInfo }, { replace: true });
        } else {
            toast.warn(`Please fill all the fields correctly. ${arr.join(", ")}`);
        }
    };

    const FormHandler = (e) => {
        setpersonalInfo({
            ...personalInfo,
            ["Phone Number"]: numvalue,
            profile: profilePic,
            DOB: startDate.toISOString().substring(0, 10),
        });
        e.preventDefault();
        setuserInfo((prevState) => ({
            ...prevState,
            personalRecord: personalInfo,
        }));


        CheckFormValid();
        // 
    };

    const ClickHandler = (type) => {
        if (type === "new") {
            sethidePassword({
                ...hidePassword,
                password: !hidePassword.password,
            });
        } else if (type === "confirm") {
            sethidePassword({
                ...hidePassword,
                confirmPassword: !hidePassword.confirmPassword,
            });
        }
    };

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }

    function showPosition(position) {
        setpersonalInfo({
            ...personalInfo,
            long: position.coords.longitude,
            lat: position.coords.latitude,
        });

    }

    useEffect(() => {
        setpersonalInfo({ ...personalInfo, profile: profilePic });
    }, [profilePic.url]);

    useEffect(() => {
        setpersonalInfo({ ...personalInfo, ["Phone Number"]: numvalue });
        if (numvalue && (numvalue.length > 13 || numvalue.length < 10)) {

            setValidPhone("Invalid Phone Number");
        } else {

            setValidPhone("");
        }
    }, [numvalue]);

    useEffect(() => {
        setpersonalInfo({ ...personalInfo, ["DOB"]: startDate });
    }, [startDate]);

    //check entered date is valid (18 years and 100 years)
    const ageLimit = (today, diff) => {
        let yyyy = today.getFullYear() - diff;
        let string = today.toISOString().replace(`${today.getFullYear()}`, yyyy)

        return new Date(string)
    }

    //default image
    const defaultImage =
        "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-image-182145777.jpg";

    return (
        <>
            <div className="md:mx-32">
                <Header />
                <div className="m-3 -mt-3 bg-gray rounded-b-lg min-h-screen min-w-screen p-2 text-text overflow-hidden ">

                    <form className="rounded-lg min-w-screen p-2" onSubmit={FormHandler}>

                        <div className={`flex justify-between mx-3 p-4 bg-primary bg-opacity-20 ${isExpandedPersonalInfo ? 'rounded-t-md' : 'rounded-md'}`}>
                            <div className="font-semibold">PERSONAL INFORMATION</div>
                            <div className="cursor-pointer" onClick={toggleExpandPersonalInfo}>
                                {isExpandedPersonalInfo ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                        </div>

                        {isExpandedPersonalInfo &&
                            <div className="mx-3 p-4 pb-10 rounded-b-md bg-white">
                                <div className="m-2 relative mb-5 flex items-center">
                                    <img
                                        className="h-24 w-24 rounded-full object-cover"
                                        src={
                                            userInfo?.personalRecord?.profile?.url ||
                                            profilePic.url ||
                                            defaultImage
                                        }
                                        alt='user'
                                    />
                                    <div className="relative mx-2">
                                        <input
                                            className="Upload-class h-20 w-20 rounded-full absolute"
                                            type="file"
                                            accept="image/png, image/jpg, image/jpeg"
                                            onChange={UploadProfile}
                                        />
                                        <span className="h-10 w-10 b-2 text-text font-bold rounded-full cursor-pointer flex justify-center items-center">
                                            <MdAddAPhoto />{" "}
                                        </span>
                                    </div>
                                    {isUploading && <div className="absolute flex w-24 items-center justify-center"> <Spin /> </div>}
                                </div>

                                <div className="grid grid-cols-1 gap-y-16 md:grid-cols-3 md:gap-x-16 md:gap-y-8">
                                    <div>
                                        <label>First Name *</label>
                                        <input
                                            type="text"
                                            defaultValue={personalInfo["First Name"]}
                                            id="First Name"
                                            placeholder="Enter First Name"
                                            className={InputClass}
                                            onChange={InputHandler}
                                            required
                                        />
                                        <p className={errorClass}>
                                            {validation["First Name"] && validation["First Name"]}
                                        </p>
                                    </div>

                                    <div>
                                        <label>Middle Name</label>
                                        <input
                                            type="text"
                                            id="Middle Name"
                                            defaultValue={personalInfo["Middle Name"]}
                                            placeholder="Enter Middle Name"
                                            className={InputClass}
                                            onChange={InputHandler}
                                        />
                                        <p className={errorClass}>
                                            {validation["Middle Name"] && validation["Middle Name"]}
                                        </p>
                                    </div>

                                    <div>
                                        <label>Last Name *</label>
                                        <input
                                            type="text"
                                            defaultValue={personalInfo["Last Name"]}
                                            id="Last Name"
                                            placeholder="Enter Last Name"
                                            className={InputClass}
                                            onChange={InputHandler}
                                            required
                                        />
                                        <p className={errorClass}>
                                            {validation["Last Name"] && validation["Last Name"]}
                                        </p>
                                    </div>

                                    <div>
                                        <label>Gender *</label>
                                        <div className="border bg-white rounded-lg px-3">
                                            <select
                                                id="Gender"
                                                className={selectClass}
                                                onChange={InputHandler}
                                                defaultValue={personalInfo["Gender"]}
                                                required
                                            >
                                                {/* <option value="" selected disabled hidden>Select Gender</option> */}
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        <label>Phone Number *</label>

                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            id="Phone Number"
                                            className={InputClass}
                                            value={numvalue}
                                            onChange={setValue}
                                            required
                                        />
                                        <p className={errorClass}> {validPhone}</p>
                                    </div>

                                    <div>
                                        <label>Date of Birth ( mm / dd / yyyy ) *</label>
                                        <DatePicker
                                            selected={startDate}
                                            className={InputClass}
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={50}
                                            onChange={(date) => {
                                                setStartDate(date);
                                                ValidateInput("DOB", startDate);
                                            }}
                                            maxDate={new Date()}
                                            // maxDate={ageLimit(new Date(), 18)}
                                            // minDate={ageLimit(new Date(), 100)}
                                            required
                                        />
                                        <p className={errorClass}>
                                            {validation["DOB"] && validation["DOB"]}
                                        </p>
                                    </div>

                                    <div>
                                        <label>Email *</label>
                                        <input
                                            defaultValue={personalInfo.Email}
                                            type="email"
                                            id="Email"
                                            placeholder="Enter Email"
                                            onChange={InputHandler}
                                            className={InputClass}
                                            required
                                        />
                                        <p className={errorClass}>
                                            {validation["Email"] && validation["Email"]}
                                        </p>
                                    </div>

                                    <div>
                                        <label>Password *</label>
                                        <div className="relative">
                                            <input
                                                defaultValue={personalInfo.Password}
                                                type={`${hidePassword.password ? "text" : "password"}`}
                                                id="Password"
                                                placeholder="Enter Password"
                                                className={InputClass}
                                                onChange={InputHandler}
                                                required
                                            />

                                            {!hidePassword.password ? (
                                                <AiOutlineEye
                                                    className="absolute right-2 text-lg bottom-2 text-text"
                                                    onClick={() => {
                                                        ClickHandler("new");
                                                    }}
                                                />
                                            ) : (
                                                <AiOutlineEyeInvisible
                                                    className="absolute right-2 text-lg bottom-2 text-text"
                                                    onClick={() => {
                                                        ClickHandler("new");
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <p className={errorClass}>
                                            {validation["Password"] && validation["Password"]}
                                        </p>
                                    </div>

                                    <div>
                                        <label>Confirm Password *</label>
                                        <div className="relative">
                                            <input
                                                defaultValue={personalInfo["Confirm Password"]}
                                                type={`${hidePassword.confirmPassword ? "text" : "password"
                                                    }`}
                                                id="Confirm Password"
                                                placeholder="Confirm Password"
                                                className={InputClass}
                                                onChange={InputHandler}
                                                required
                                            />

                                            {!hidePassword.confirmPassword ? (
                                                <AiOutlineEye
                                                    className="absolute right-2 text-lg bottom-2 text-text"
                                                    onClick={() => {
                                                        ClickHandler("confirm");
                                                    }}
                                                />
                                            ) : (
                                                <AiOutlineEyeInvisible
                                                    className="absolute right-2 text-lg bottom-2 text-text"
                                                    onClick={() => {
                                                        ClickHandler("confirm");
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <p className={errorClass}>
                                            {validation["Confirm Password"] &&
                                                validation["Confirm Password"]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={`flex justify-between mx-3 mt-3 p-4 bg-primary bg-opacity-20 ${isExpandedAddressInfo ? 'rounded-t-md' : 'rounded-md'}`}>
                            <div className="font-semibold">PLEASE PROVIDE YOUR CURRENT ADDRESS</div>
                            <div className="cursor-pointer" onClick={toggleExpandAddressInfo}>
                                {isExpandedAddressInfo ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                        </div>

                        {isExpandedAddressInfo &&
                            <div className="mx-3 px-4 py-5 rounded-b-md bg-white">
                                <div className="grid grid-cols-1 mb-5 gap-y-16 md:grid-cols-3 md:gap-x-14 md:gap-y-8">
                                    {/* <div>
                                        <label>Address</label>
                                        <Autocomplete
                                            name={"Address"}
                                            personalInfo={personalInfo}
                                            setpersonalInfo={setpersonalInfo}
                                            Length={searchLength}
                                            setLength={setsearchLength}
                                            defaultValue={personalInfo?.address}
                                        />
                                    </div> */}

                                    <div>
                                        <label>Address *</label>
                                        <input
                                            defaultValue={personalInfo?.address}
                                            type="text"
                                            id="address"
                                            placeholder="Enter City"
                                            onChange={InputHandler}
                                            className={InputClass}
                                            required
                                        />
                                    </div>

                                    {/* <div>
                                        <label>City</label>
                                        <input
                                            defaultValue={personalInfo?.City}
                                            type="text"
                                            id="City"
                                            placeholder="Enter City"
                                            onChange={InputHandler}
                                            className={InputClass}
                                            required
                                        />
                                    </div> */}

                                    <div>
                                        <label>Country *</label>
                                        <div className="border rounded-lg bg-white px-3">
                                            <select
                                                name="Country"
                                                id="Country"
                                                defaultValue={personalInfo?.Country}
                                                value={personalInfo.Country}
                                                className={selectClass}
                                                onChange={InputHandler}
                                                required
                                            >
                                                <option value="">Select Country</option>
                                                {Country.getAllCountries().map((country) => (
                                                    <option key={country.isoCode} value={country.isoCode}>{country.name}</option>
                                                ))}
                                            </select>
                                            <p className={errorClass}>
                                                {validation["Country"] && validation["Country"]}
                                            </p>
                                        </div>
                                    </div>

                                    <div>
                                        <label>State *</label>
                                        <div className="border rounded-lg bg-white px-3" title='Please select country first'>
                                            <select
                                                name="State"
                                                id="State"
                                                defaultValue={personalInfo?.State}
                                                value={personalInfo?.State}
                                                className={selectClass}
                                                onChange={InputHandler}
                                                required
                                            >
                                                <option value="">Select State</option>
                                                {personalInfo.Country && State.getStatesOfCountry(personalInfo.Country).map((state) => (
                                                    <option key={state.isoCode} value={state.name}>{state.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div>
                                        <label>State</label>
                                        <input
                                            defaultValue={personalInfo?.State}
                                            list="states"
                                            type="text"
                                            id="State"
                                            placeholder="Enter State"
                                            onChange={InputHandler}
                                            className={InputClass}
                                            required
                                        />
                                        {States()}
                                    </div> */}

                                    {/* <div>
                                        <label>Country</label>
                                        <input
                                            list="countries"
                                            defaultValue={personalInfo?.Country}
                                            type="text"
                                            id="Country"
                                            placeholder="Enter Country"
                                            onChange={InputHandler}
                                            className={InputClass}
                                            required
                                        />
                                        {Countries()}
                                        <p className={errorClass}>
                                            {validation["Country"] && validation["Country"]}
                                        </p>
                                    </div> */}

                                    <div>
                                        <label>City *</label>
                                        <input
                                            defaultValue={personalInfo?.City}
                                            type="text"
                                            id="City"
                                            placeholder="Enter City"
                                            onChange={InputHandler}
                                            className={InputClass}
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label>Zip Code *</label>
                                        <input
                                            defaultValue={personalInfo?.ZipCode}
                                            type="text"
                                            id="ZipCode"
                                            placeholder="Enter Zip Code"
                                            onChange={InputHandler}
                                            className={InputClass}
                                            required
                                        />
                                        <p className={errorClass}>
                                            {validation["ZipCode"] && validation["ZipCode"]}
                                        </p>
                                    </div>

                                    <div>
                                        <label>Time Zone *</label>
                                        <div className="border rounded-lg bg-white px-3">
                                            <select
                                                name="Timezone"
                                                id="Timezone"
                                                value={personalInfo.Timezone}
                                                className={selectClass}
                                                onChange={InputHandler}
                                                required
                                            >
                                                {/* <option value="none" selected disabled hidden>Select Timezone</option> */}
                                                <option value="">Select Timezone</option>
                                                <option value="3">Pacific Time Zone (PST)</option>
                                                <option value="1">Eastern Standard Time (EST)</option>
                                                <option value="2">Central Standard Time (CST)</option>
                                                <option value="4">Mountain Standard Time (MST)</option>
                                                <option value="5">Hawaii–Aleutian Standard Time (HST)</option>
                                                <option value="6">Alaska Standard Time (AKST)</option>
                                                <option value="7">Indian Standard Time (IST)</option>
                                                <option value="8">South African Standard Time (SAST)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                        <div className={`flex justify-between mx-3 mt-3 p-4 bg-primary bg-opacity-20 ${isExpandedEvaluation ? 'rounded-t-md' : 'rounded-md'}`}>
                            <div className="font-semibold">DOCTOR EVALUATION</div>
                            <div className="cursor-pointer" onClick={toggleExpandEvaluation}>
                                {isExpandedEvaluation ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                        </div>

                        {isExpandedEvaluation &&
                            <div className="mx-3 px-4 py-5 rounded-b-md bg-white">
                                <div className="grid grid-cols-1 mb-5 gap-y-16 md:grid-cols-3 md:gap-x-14 md:gap-y-8">
                                    <div className="col-span-2">
                                        <label>For which state do you want a doctor evaluation *</label>
                                        {/* <input
                                            list="states"
                                            className={InputClass}
                                            placeholder="Enter State"
                                            defaultValue={personalInfo["State for evaluation"]}
                                            id="State for evaluation"
                                            onChange={InputHandler}
                                            required
                                        />
                                        {States()} */}
                                        <div className="border rounded-lg bg-white px-3 md:w-1/2 lg:w-1/2" title='Please select country first'>
                                            <select
                                                defaultValue={personalInfo["State for evaluation"]}
                                                className={selectClass}
                                                onChange={InputHandler}
                                                id="State for evaluation"
                                                required
                                            >
                                                <option value="">Select State</option>
                                                {personalInfo.Country && State.getStatesOfCountry(personalInfo.Country).map((state) => (
                                                    <option key={state.isoCode} value={state.name}>{state.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="flex flex-col items-center justify-center md:justify-between md:mt-5 md:flex-row">
                            <div>
                                <input type="checkbox" className="m-3" required />
                                <label>
                                    I have read, agree and fully accepted the{" "}
                                    <a href="https://thesynergyworks.com/" target='_blank' className="text-primary font-bold">
                                        {" "}
                                        Terms & Conditions.
                                    </a>
                                </label>
                            </div>
                            <button className="flex items-center justify-center space-x-3 bg-secondary px-3 py-2 w-32 rounded-lg font-bold text-white text-base mt-3 md:px-4 md:py-3">
                                <span>Next</span>
                                <HiChevronDoubleRight className="text-lg" />
                            </button>
                        </div>
                    </form>
                </div >
            </div >
        </>
    );
};

export default NewPersonal;
